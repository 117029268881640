import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Checkbox, FormControlLabel } from '@material-ui/core';

import config from 'config/config.json';

const PopupData = config.popup;

const Popup = (props) => {

  const seconds = PopupData.seconds;
  const message = PopupData.message;
  const title = PopupData.title;

  const [open, setOpen] = React.useState(false);

  // initialize timeLeft with the seconds prop
  const [timeLeft, setTimeLeft] = React.useState(seconds);

  const isPromptDisabled = () => {
    return sessionStorage.getItem('disable_prompt') === "true";
  }

  const [checked, setChecked] = React.useState(isPromptDisabled());

  React.useEffect(() => {

    // exit early when we reach 0
    if (!timeLeft && !isPromptDisabled()) {
      setOpen(true);
      return;
    }

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  const handleClose = () => {
    setOpen(false);
  };

  const setPromptDisabled = (event) => {
    const value = event.target.checked;

    sessionStorage.setItem('disable_prompt', value);
    setChecked(value);
  };



  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span dangerouslySetInnerHTML={{ __html: message }}></span>

            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={setPromptDisabled}
                  color="primary"
                />
              }
              label="diesen Hinweis für die aktuelle Sitzung ausblenden"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};



export default Popup;