import React from 'react';
import PropTypes from 'prop-types';

import {NavLink} from 'react-router-dom';

import {withStyles} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';


const styles = theme => ({
    link: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: 14
    },
    linkActive: {
        color: theme.custom.colors.active,
    },
    list: {

    }
});


const ListItemLink = (props) => {
    const {primary, to} = props;
    return (
        <li className={props.classes.list}>
            <ListItem className={props.classes.link} activeClassName={props.classes.linkActive}
                      component={NavLink} to={to} exact={props.exact}>
                {primary}
            </ListItem>
        </li>
    );
};

ListItemLink.propTypes = {
    primary: PropTypes.node.isRequired,
    to: PropTypes.string.isRequired,
    exact: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListItemLink);