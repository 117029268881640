import React from 'react';

import SearchResult from 'components/Search/Result';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Popup from 'components/Popup';

const Results = (props) => (
    <React.Fragment>
        {/* <Popup/> */}
        <SearchResult defaultValue={props.queryString} />
    </React.Fragment>
);


Results.propTypes = {
    queryString: PropTypes.string,
};

Results.defaultProps = {
    queryString: null
};

export default withRouter(Results);