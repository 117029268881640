import React from 'react';
import image from './GAP-Suche_Infografik.jpg';

import { withTheme, withStyles } from '@material-ui/core/styles';



const styles = theme => ({
  content: {
    ...theme.custom.contentpage.style
  },
  center: {
    ...theme.custom.verticalCenter.style
  },
  container: {
    ...theme.custom.container.style
  },
  responsive: {
    maxWidth: '80%',
    height: 'auto'
  },
  table: {
    border: "1px solid black;",
    borderColor: "#ddd"
  }
});

const Imprint = (props) => {
  return (
    <section className={props.classes.content}>
      <div className={props.classes.container}>
        <h2>Weitere Informationen</h2>
        <p>Wir, ein Forscherteam des Universitätsklinikums Freiburg und der Technischen Universität Dortmund, bestehend aus ärztlichen, psychologischen und Kommunikations-Wissenschaftlern, sowie Softwareexperten, haben eine Möglichkeit entwickelt selbst entscheiden zu können, was bei einer Recherche im Internet in Bezug auf Gesundheitsfragen wichtig ist.</p>
        <p>So entwickelten wir nach Sichtung der einschlägigen Literatur, Kriterien, die uns wichtig erschienen, um beurteilen zu können, ob eine Website vertrauenswürdig, verständlich, aktuell und/oder nutzerfreundlich ist (z.B. Eysenbach &amp; Köhler, 2002). Die hierfür entwickelten Kriterien, 74 an der Zahl, wurden als Fragen formuliert und bilden die oben erwähnten vier Kategorien (Vertrauenswürdigkeit, Verständlichkeit, Aktualität und Nutzerfreundlichkeit) ab.</p>
        <p>Wir zogen also 54 Websites (Domains) mit medizinisch relevanten Inhalten aus dem Netz (Crawling), welche in einer Datenbank gespeichert werden. Die ausgewählten Websites basieren auf einer Umfrage der Bertelsmann-Stiftung (Rossmann et al., 2018; Stiftung, 2018), ergänzt um populäre, häufig genutzte Seiten. Von jeder dieser Websites wurden fünf Unterseiten zufällig ausgewählt und jeweils nach den definierten Kriterien bewertet. Die so zustande gekommenen Bewertungen pro Domain fließen in die Datenbank mit ein.</p>
        <p>Bei einer Suche im Suchfeld wird nun diese Datenbank nach dem Suchbegriff sowie dessen Synonymen durchsucht und nach Trefferhäufigkeit in der Überschrift und im Text aufgelistet. Es wird die Überschrift des Textes angezeigt, der Link, ein Ausschnitt aus dem Text.</p>
        {/* <div style={{ textAlign: 'center' }}><img src={image} class={props.classes.responsive}></img></div> */}
        <h3>Suchindex</h3>
        <p>Unsere Suche durchsucht Inhalte der folgenden 37 Domains:</p>
        <table class={props.classes.table}>
          <thead>
            <tr>
              <th>Domain</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>awmf.org</td>
            </tr>
            <tr>
              <td>bzfe.de</td>
            </tr>
            <tr>
              <td>bzga-essstoerungen.de</td>
            </tr>
            <tr>
              <td>drugcom.de</td>
            </tr>
            <tr>
              <td>familienplanung.de</td>
            </tr>
            <tr>
              <td>gesund-ins-leben.de</td>
            </tr>
            <tr>
              <td>gesund.bund.de</td>
            </tr>
            <tr>
              <td>gesundheit-leicht-verstehen.de</td>
            </tr>
            <tr>
              <td>gesundheit.gv.at</td>
            </tr>
            <tr>
              <td>gesundheitsinformation.de</td>
            </tr>
            <tr>
              <td>hardingcenter.de</td>
            </tr>
            <tr>
              <td>hilfeportal-missbrauch.de</td>
            </tr>
            <tr>
              <td>hilfetelefon.de</td>
            </tr>
            <tr>
              <td>hilfetelefon.de</td>
            </tr>
            <tr>
              <td>igel-monitor.de</td>
            </tr>
            <tr>
              <td>impfen-info.de</td>
            </tr>
            <tr>
              <td>in-form.de</td>
            </tr>
            <tr>
              <td>infektionsschutz.de</td>
            </tr>
            <tr>
              <td>kbv.de</td>
            </tr>
            <tr>
              <td>kenn-dein-limit.de</td>
            </tr>
            <tr>
              <td>kindergesundheit-info.de</td>
            </tr>
            <tr>
              <td>kinderstarkmachen.de</td>
            </tr>
            <tr>
              <td>krebsdaten.de</td>
            </tr>
            <tr>
              <td>krebsinformationsdienst.de</td>
            </tr>
            <tr>
              <td>leitlinien.de</td>
            </tr>
            <tr>
              <td>loveline.de</td>
            </tr>
            <tr>
              <td>maennergesundheitsportal.de</td>
            </tr>
            <tr>
              <td>medizin-transparent.at</td>
            </tr>
            <tr>
              <td>organspende-info.de</td>
            </tr>
            <tr>
              <td>patienten-information.de</td>
            </tr>
            <tr>
              <td>patienten-universitaet.de</td>
            </tr>
            <tr>
              <td>psychenet.de</td>
            </tr>
            <tr>
              <td>rauchfrei-info.de</td>
            </tr>
            <tr>
              <td>rki.de</td>
            </tr>
            <tr>
              <td>tala-med.info</td>
            </tr>
            <tr>
              <td>wissenwaswirkt.org</td>
            </tr>
            <tr>
              <td>zanzu.de</td>
            </tr>
          </tbody>
        </table>
        <h3>Literaturangabe</h3>
        <ul>
          <li>Eysenbach, G., &amp; Köhler, C. (2002). How do consumers search for and appraise health information on the world wide web? Qualitative study using focus groups, usability tests, and in-depth interviews. Bmj, 324(7337), 573–577.</li>
          <li>Rossmann, C., Lampert, C., Stehr, P., &amp; Grimm, M. (2018). Nutzung und Verbreitung von Gesundheitsinformationen. Ein Literaturüberblick zu theoretischen Ansätzen und empirischen Befunden. Bertelsmann Stiftung. doi, 10, 2017051.</li>
          <li>Stiftung, B. (2018). Gesundheitsinfos Wer suchet, der findet–Patienten mit Dr. Google zufrieden, aus der Reihe Daten, Analysen, Perspektiven, 2.</li>
        </ul>
      </div>
    </section>
  )
}

export default withTheme(withStyles(styles)(Imprint));