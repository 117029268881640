import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import {withTheme, withStyles} from '@material-ui/core/styles';

// import Accessibility from './Footer/Accessibility';
import FooterNavigation from './Footer/FooterNavigation';

const styles = theme => ({
    footer: {
        ...theme.custom.footer.style
    },
    center: {
        ...theme.custom.verticalCenter.style
    },
    container: {
        ...theme.custom.container.style
    }
});


const Footer = (props) => {
    return (
        <footer className={props.classes.footer}>
            <div className={props.classes.container}>
                <Grid container>
                    <Grid item xs={8} sm={4} md={2}>
                      <a href="https://www.uniklinik-freiburg.de/" target="_blank" rel="noopener noreferrer">
                        <div className={props.classes.center}>
                            <img
                                style={props.theme.custom.footer.logos.ukl.style}
                                src={props.theme.custom.footer.logos.ukl.file}
                                alt="Universitätsklinikum Freiburg"
                            />
                        </div>
                      </a>
                    </Grid>
                    <Grid item xs={8} sm={4} md={2}>
                      <a href="https://www.cochrane.org/" target="_blank" rel="noopener noreferrer">
                        <div className={props.classes.center}>
                          
                            <img
                                style={props.theme.custom.footer.logos.cochrane.style}
                                src={props.theme.custom.footer.logos.cochrane.file}
                                alt="Cochrane Deutschland"
                            />
                         
                        </div>
                      </a>
                    </Grid>
                    <Grid item xs={8} sm={4} md={2}>
                      <a href="https://wissenschaftsjournalismus.tu-dortmund.de" target="_blank" rel="noopener noreferrer">
                        <div className={props.classes.center}>
                          
                            <img
                                style={props.theme.custom.footer.logos.tud.style}
                                src={props.theme.custom.footer.logos.tud.file}
                                alt="Cochrane Deutschland"
                            />
                         
                        </div>
                      </a>
                    </Grid>
                    <Grid item xs={8} sm={4} md={2}>
                      <a href="https://www.mri.tum.de/" target="_blank" rel="noopener noreferrer">
                        <div className={props.classes.center}>
                            <img
                                style={props.theme.custom.footer.logos.mri.style}
                                src={props.theme.custom.footer.logos.mri.file}
                                alt="Klinikum rechts der Isar der Technischen Universität München"
                            />
                        </div>
                      </a>
                    </Grid>
                    <Grid item xs={8} sm={4} md={2}>
                      <a href="https://www.tum.de/" target="_blank" rel="noopener noreferrer">
                        <div className={props.classes.center}>
                            <img
                                style={props.theme.custom.footer.logos.tum.style}
                                src={props.theme.custom.footer.logos.tum.file}
                                alt="Technische Universität München"
                            />
                        </div>
                      </a>
                    </Grid>
                    <Grid item xs={8} sm={4} md={2}>
                        <FooterNavigation/>
                    </Grid>
                </Grid>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    theme: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withTheme(withStyles(styles)(Footer));