import React from 'react';

import Start from 'pages/Search/Start';
import Results from 'pages/Search/Results';

import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import qs from 'qs';

const Search = (props) => {
    const queryString = qs.parse(props.location.search, { ignoreQueryPrefix: true }).q;

    return (
        <div>
            {queryString ? <Results queryString={queryString}/> : <Start/>}
        </div>
    )
};

Search.propTypes = {
    match: PropTypes.object.isRequired
};

export default withRouter(Search);