import React from 'react';
import { withTheme, withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import { Cookies } from "react-cookie-consent"

import { useMatomo } from '@datapunt/matomo-tracker-react';

const styles = theme => ({
  content: {
    ...theme.custom.contentpage.style
  },
  center: {
    ...theme.custom.verticalCenter.style
  },
  container: {
    ...theme.custom.container.style
  }
});

const Privacy = (props) => {
  const consent = localStorage.getItem('consent');

  const { pushInstruction } = useMatomo();

  const resetMatomoSettings = () => {
    localStorage.removeItem('consent');
    Cookies.remove('consent');
    Cookies.remove('consent-legacy');
    pushInstruction('forgetConsentGiven');

    window.location.reload();
  }

  return (
    <section className={props.classes.content}>
      <div className={props.classes.container}>
        <h2>Datenschutzerklärung nach der DSGVO</h2>
    <p>Der Schutz der personenbezogenen Daten während des gesamten Nutzungsprozesses ist uns wichtig. Wir möchten, dass Sie sich beim Besuch unserer Internetseiten sicher fühlen. Nachfolgend erläutern wir, welche Informationen wir während Ihres Besuches auf unseren Webseiten erfassen und wie diese genutzt werden.</p>
    <h3>I. Ansprechpartner</h3>
    <p><strong>1. Name und Anschrift des Verantwortlichen</strong>
        <br/>Verantwortlich im Sinne der Datenschutz-Grundverordnung und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist das:</p>
    <p>Universitätsklinikum Freiburg
        <br/>Breisacher Straße 153
        <br/>D 79110 Freiburg
        <br/>Telefon: +49 761 270-0
        <br/>Telefax: +49 761 270-20200
        <br/><a href="mailto:info@uniklinik-freiburg.de" target="_blank" rel="noopener noreferrer">info@uniklinik-freiburg.de</a></p>
    <p><strong>2. Datenschutzbeauftragter des Universitätsklinikum Freiburg</strong>
        <br/>Universitätsklinikum Freiburg
        <br/>Datenschutzbeauftragter
        <br/>Herr Helwig Opel
        <br/>Agnesenstraße 6 – 8
        <br/>79106 Freiburg
        <br/>Telefonnummer des Universitätsklinikums Freiburg: 0761 270-0</p>
    <h3>II. Personenbezogene Daten</h3>
    <p><strong>1. Verarbeitung von personenbezogenen Daten</strong>
        <br/>Personenbezogene Daten sind Einzelangaben über persönliche oder sachliche Verhältnisse einer bestimmten oder bestimmbaren natürlichen Person. Darunter fallen Informationen wie z. B. Vor- und Zuname, Adresse, Geburtsdatum, Gesundheitszustand, Informationen über eine medizinische Behandlung. Angaben, die nicht mittel- oder unmittelbar mit einer Person verbunden sind, wie z. B. besuchte Webseiten oder Anzahl der Nutzer einer Seite, sind keine personenbezogenen Daten.
        <br/>Wir verarbeiten personenbezogene Daten unserer Nutzer grundsätzlich nur, soweit dies zur Bereitstellung einer funktionsfähigen Website sowie unserer Inhalte und Leistungen erforderlich ist. Die Verarbeitung personenbezogener Daten unserer Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers. Eine Ausnahme gilt in solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.</p>
    <p><strong>2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten</strong>
        <br/>Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1 lit. a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.
        <br/>Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei die betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1 lit. b DSGVO als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.
        <br/>Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als Rechtsgrundlage.
        <br/>Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.
        <br/>Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als Rechtsgrundlage für die Verarbeitung.</p>
    <p><strong>3. Datenlöschung und Speicherdauer</strong>
        <br/>Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.</p>
    <h3>III. Verwendung von Cookies</h3>
    <p>Unsere Webseite verwendet Cookies. Bei Cookies handelt es sich um Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem Computersystem des Nutzers gespeichert werden. Ruft ein Nutzer eine Website auf, so kann ein Cookie auf dem Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der Website ermöglicht.
        <br/>Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu gestalten. Einige Elemente unserer Internetseite erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel identifiziert werden kann. In den Cookies werden dabei nur die Log-In-Informationen gespeichert und übermittelt.</p>
    <p><strong>1. Rechtsgrundlage für die Verwendung von Cookies</strong>
        <br/>Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von Cookies ist Art. 6 Abs. 1 lit. f DSGVO.</p>
    <p><strong>2. Zweck der Verwendung von Cookies</strong>
        <br/>Die Verwendung technisch notwendiger Cookies vereinfacht die Nutzung von Websites für die Nutzer. Der Einsatz von Cookies ermöglicht beispielsweise das Merken der Login-Informationen und somit die Wiedererkennung des Browsers auch nach einem Seitenwechsel. Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden nicht zur Erstellung von Nutzerprofilen, sondern nur zur wissenschaftlichen Analyse des Nutzungsverhaltens im Rahmen des zeitlich begrenzten GAP-Forschungsprojektes verwendet. In diesen Zwecken liegt auch unser berechtigtes Interesse in der Verarbeitung der personenbezogenen Daten nach Art. 6 Abs. 1 lit. f DSGVO.</p>
    <p><strong>3. Dauer der Speicherung, Widerspruchs- und Beseitigungsmöglichkeit</strong>
        <br/>Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unserer Seite übermittelt. Daher haben Sie als Nutzer auch die volle Kontrolle über die Verwendung von Cookies. Durch eine Änderung der Einstellungen in Ihrem Internetbrowser können Sie üblicherweise die Übertragung von Cookies von Webseiten deaktivieren oder einschränken oder Sie können bereits gespeicherte Cookies löschen.
        <br/>Diese Möglichkeiten der Deaktivierung, Einschränkung oder Löschung von Cookies haben wir für suche.talamed.info gesperrt, da die im Rahmen des GAP-Forschungsprojektes eingesetzten Cookies zur wissenschaftlichen Analyse des Nutzungsverhaltens unbedingt erforderlich sind. Der Analyse Ihres Nutzerverhaltens haben Sie bei der Einwilligung in die Studienteilnahme zugestimmt. Der Sperrung der Cookie-Deaktivierung haben Sie beim ersten Login zu suche.talamed.info zugestimmt. Sie haben jederzeit die Möglichkeit, eine Entsperrung unter <a href="mailto:sec@ifem.uni-freiburg.de" target="_blank" rel="noopener noreferrer">sec@ifem.uni-freiburg.de</a> zu beantragen.</p>
    <h3>IV. Analyse der Website-Nutzung</h3>
    <p><strong>1. Umfang der Verarbeitung personenbezogener Daten</strong>
        <br/>Wir nutzen auf unserer Website das Open-Source-Software-Tool Matomo (ehemals PIWIK) zur Analyse des Surfverhaltens unserer Nutzer. Die Software setzt ein Cookie auf dem Rechner der Nutzer. Die Matomo Software legt für jeden Nutzer ein Nutzer-Profil an und speichert dazu folgende Daten:</p>
    <p>(1) Ein eindeutiges aber nicht identifizierbares Kennzeichen (Pseudonym) für jeden Nutzer
        <br/>(2) Die anonymisierte IP-Adresse („Internet-Adresse“) des Nutzers.
        <br/>(3) Die Region, aus der der Zugriff erfolgt ist.
        <br/>(4) Der Internetprovider, über den der Zugriff erfolgt ist.
        <br/>(5) Das Betriebssystem und der Browser, von dem aus der Zugriff erfolgt ist.
        <br/>(6) Die aufgerufene Webseite.
        <br/>(7) Die Website, von der der Nutzer auf die aufgerufene Webseite gelangt ist (Referrer).
        <br/>(8) Die Unterseiten, die von der aufgerufenen Webseite aus aufgerufen werden.
        <br/>(9) Die Verweildauer auf der Webseite.
        <br/>(10) Die Häufigkeit des Aufrufs der Webseite.
        <br/>(11) Bestimmte Aktionen, die der Nutzer auf der Webseite ausgeführt hat (Video betrachtet, Downloads durchgeführt).</p>
    <p>Die Software läuft dabei ausschließlich auf den Servern unserer Webseite. Eine Speicherung der personenbezogenen Daten der Nutzer findet nur dort statt. Eine Weitergabe der Daten an Dritte erfolgt nicht.
        <br/>Die Software ist so eingestellt, dass die IP-Adressen nicht vollständig gespeichert werden, sondern 2 Bytes der IP-Adresse maskiert werden (Bsp.: 192.168.xxx.xxx). Auf diese Weise ist eine Zuordnung der gekürzten IP-Adresse zum aufrufenden Rechner nicht mehr möglich.</p>
    <p><strong>2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten</strong>
        <br/>Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten der Nutzer ist Art. 6 Abs. 1 lit. f DSGVO.</p>
    <p><strong>3. Zweck der Datenverarbeitung</strong>
        <br/>Die Verarbeitung der personenbezogenen Daten der Nutzer ermöglicht uns eine Analyse des Surfverhaltens unserer Nutzer. Wir sind in durch die Auswertung der gewonnen Daten in der Lage, Informationen über die Nutzung der einzelnen Komponenten unserer Webseite zusammenzustellen. Dies hilft uns dabei unsere Webseite und deren Nutzerfreundlichkeit stetig zu verbessern. In diesen Zwecken liegt auch unser berechtigtes Interesse in der Verarbeitung der Daten nach Art. 6 Abs. 1 lit. f DSGVO. Durch die Anonymisierung der IP-Adresse wird dem Interesse der Nutzer an deren Schutz personenbezogener Daten hinreichend Rechnung getragen.</p>
    <p><strong>4. Dauer der Speicherung</strong>
        <br/>Die Daten werden gelöscht, sobald sie für unsere Aufzeichnungszwecke nicht mehr benötigt werden.</p>
    <h3>V. Rechte der betroffenen Person</h3>
    <p>Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte gegenüber dem Verantwortlichen zu.</p>
    <p><strong>1. Auskunftsrecht</strong>
        <br/>Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden.
        <br/>Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende Informationen Auskunft verlangen:</p>
    <p>(1) die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;
        <br/>(2) die Kategorien von personenbezogenen Daten, welche verarbeitet werden;
        <br/>(3) die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;
        <br/>(4) die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;
        <br/>(5) das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;
        <br/>(6) das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
        <br/>(7) alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;
        <br/>(8) das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.
        <br/>(9) Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden personenbezogenen Daten in ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden. Dieses Auskunftsrecht kann insoweit beschränkt werden, als es voraussichtlich die Verwirklichung der Forschungs- oder Statistikzwecke unmöglich macht oder ernsthaft beeinträchtigt und die Beschränkung für die Erfüllung der Forschungs- oder Statistikzwecke notwendig ist.</p>
    <p><strong>2. Recht auf Berichtigung</strong>
        <br/>Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem Verantwortlichen, sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig sind. Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.
        <br/>Ihr Recht auf Berichtigung kann insoweit beschränkt werden, als es voraussichtlich die Verwirklichung der Forschungs- oder Statistikzwecke unmöglich macht oder ernsthaft beeinträchtigt und die Beschränkung für die Erfüllung der Forschungs- oder Statistikzwecke notwendig ist.</p>
    <p><strong>3. Recht auf Einschränkung der Verarbeitung</strong>
        <br/>Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:</p>
    <p>(1) wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;
        <br/>(2) die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;
        <br/>(3) der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder
        <br/>(4) wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben und noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen überwiegen.</p>
    <p>Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.
        <br/>Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie von dem Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird.
        <br/>Ihr Recht auf Einschränkung der Verarbeitung kann insoweit beschränkt werden, als es voraussichtlich die Verwirklichung der Forschungs- oder Statistikzwecke unmöglich macht oder ernsthaft beeinträchtigt und die Beschränkung für die Erfüllung der Forschungs- oder Statistikzwecke notwendig ist.</p>
    <p><strong>4. Recht auf Löschung</strong>
        <br/>a) Löschungspflicht
        <br/>Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet, diese Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:</p>
    <p>(1) Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.
        <br/>(2) Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
        <br/>(3) Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.
        <br/>(4) Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.
        <br/>(5) Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.
        <br/>(6) Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.</p>
    <p>b) Information an Dritte
        <br/>Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.</p>
    <p>c) Ausnahmen
        <br/>Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist</p>
    <p>(1) zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
        <br/>(2) zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;
        <br/>(3) aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO;
        <br/>(4) für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder
        <br/>(5) zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
    <p><strong>5. Recht auf Unterrichtung</strong>
        <br/>Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden.
        <br/>Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu werden.</p>
    <p><strong>6. Recht auf Datenübertragbarkeit</strong>
        <br/>Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern</p>
    <p>(1) die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO beruht und
        <br/>(2) die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</p>
    <p>In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.
        <br/>Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.</p>
    <p><strong>7. Widerspruchsrecht</strong>
        <br/>Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
        <br/>Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
        <br/>Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
        <br/>Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.
        <br/>Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.
        <br/>Sie haben auch das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, bei der Verarbeitung Sie betreffender personenbezogener Daten, die zu wissenschaftlichen oder historischen Forschungszwecken oder zu statistischen Zwecken gem. Art. 89 Abs. 1 DSGVO erfolgt, dieser zu widersprechen.
        <br/>Ihr Widerspruchsrecht kann insoweit beschränkt werden, als es voraussichtlich die Verwirklichung der Forschungs- oder Statistikzwecke unmöglich macht oder ernsthaft beeinträchtigt und die Beschränkung für die Erfüllung der Forschungs- oder Statistikzwecke notwendig ist.</p>
    <p><strong>8. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</strong>
        <br/>Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.</p>
    <p><strong>9. Automatisierte Entscheidung im Einzelfall einschließlich Profiling</strong>
        <br/>Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die Entscheidung</p>
    <p>(1) für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem Verantwortlichen erforderlich ist,
        <br/>(2) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder
        <br/>(3) mit Ihrer ausdrücklichen Einwilligung erfolgt.</p>
    <p>Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g DSGVO gilt und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.
        <br/>Hinsichtlich der in (1) und (3) genannten Fälle trifft der Verantwortliche angemessene Maßnahmen, um die Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.</p>
    <p><strong>10. Recht auf Beschwerde bei einer Aufsichtsbehörde</strong>
        <br/>Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
        <br/>Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.</p>
        <p><strong>11. Matomo</strong>
        <br/>Zur statistischen Auswertung setzt das Universitätsklinikums Freiburg auf dieser Webseite "Matomo" (früher "PIWIK") ein. Das ist ein Open-Source-Tool zur Web-Analyse. Mit Matomo werden keine Daten an Server übermittelt, die außerhalb der Kontrolle des Universitätsklinikums Freiburgs liegen (siehe Datenschutzerklärung). Matomo ist deaktiviert, wenn Sie unsere Webseite besuchen. Erst wenn Sie aktiv einwilligen, wird Ihr Nutzungsverhalten anonymisiert erfasst.
        <br/>Matomo verwendet sog. Cookies. Das sind Textdateien, die auf Ihrem Computer gespeichert werden und die es dem Universitätsklinikum Freiburg möglich machen, die Benutzung seiner Webseite zu analysieren. Dazu werden die durch den Cookie gewonnenen Informationen über die Nutzung an den Server des Universitätsklinikums Freiburgs übertragen und gespeichert, damit das Nutzungsverhalten ausgewertet werden kann. Ihre IP-Adresse wird umgehend anonymisiert; damit bleiben Sie als Nutzer anonym. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite werden nicht an Dritte weitergegeben.
        <br/>Das Universitätsklinikum Freiburg versteht diese Analyse als Bestandteil seines Internet-Services. Es möchte damit die Webseite weiter verbessern und noch mehr an die Bedürfnisse der Nutzerinnen und Nutzer anpassen.
        <br/>Sie können sich hier entscheiden, ob in Ihrem Browser ein Webanalyse-Cookie abgelegt werden darf, um dem Bundespresseamt die Erfassung und Analyse statistischer Daten zu ermöglichen.
        <br/>
        <br/>Status: Momentan werden ihre Daten {consent !== 'in' && <b>nicht</b>} erfasst!
        <br/>
        <br/><Button onClick={() => resetMatomoSettings()} variant="contained" color="secondary">Matomo Cookie Einstellungen neu setzen</Button>
        <br/>
        </p>
      </div>
    </section>
  )
}

export default withTheme(withStyles(styles)(Privacy));