import React from 'react';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import config from 'config/config.json';

const PopupData = config.popup;

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const theme = createMuiTheme({
    palette: {
      primary: { main: "#dc004e" },
      secondary: { 
        light: "#ff4081",
        main: "#dc004e",
        dark: "#c51162",
        contrastText: '#fff'
      },
    },
  });

  return (
    <div style={{ display: "flex", position: "absolute", right: 0 }}>
      {/* <ThemeProvider theme={theme}>
        <Button style={{backgroundColor: "#dc004e", color: "#fff"}} variant="outlined" color="secondary" onClick={handleClickOpen}>
          zur Umfrage
        </Button>
      </ThemeProvider> */}
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Umfrage
            </Typography>
          </Toolbar>
        </AppBar>
        <iframe src={PopupData.url} height="100%" title="Umfrage" />
      </Dialog>
    </div>
  );
}