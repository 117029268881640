import PropTypes from "prop-types";
import React from "react";
import _ from 'lodash';

import FacetValue from "@elastic/react-search-ui-views/es/types/FacetValue";
import appendClassName from "@elastic/react-search-ui-views/es/view-helpers/appendClassName";

import { withRouter } from 'react-router-dom';
import Slider from '@material-ui/core/Slider';
import qs from 'qs';
import withWidth from '@material-ui/core/withWidth';

function valuetext(value) {
  return `${value.name}`;
}

class SingleSliderFacet extends React.Component {

  componentDidMount() {
    const field = this.props.label;

    const mapValues = {
      'unwichtig': 0,
      'eher unwichtig': 1,
      'wichtig': 2,
      'sehr wichtig': 3
    }

    const getVariables=qs.parse(decodeURI(this.props.location.search), {
      ignoreQueryPrefix: true
    });

    const filter = _.find(getVariables.filters, { field });
    let getFilter = null;

    if (filter) {
      sessionStorage.setItem(
        filter.values[0].field,
        mapValues[filter.values[0].name]
      );

      getFilter = mapValues[filter.values[0].name]
      this.onChange(null, getFilter)
    } else {
      const defaultVal = getFilter || sessionStorage.getItem(field) || 0;
      sessionStorage.getItem(`${field}_set`) || (this.onChange(null, defaultVal));
    }
      
    // console.log(sessionStorage.getItem(`${field}_set`));
    // console.log(sessionStorage.getItem(`${field}`));
  }

  onChange = (event, newValue) => {
    const field = this.props.label;
    const value = this.props.options[newValue].value;

    this.props.onChange(value);

    sessionStorage.setItem(field, newValue);
    sessionStorage.setItem(`${field}_set`, true);

    this.props.trackEvent();    
  }

  render() {
    const { className, label, options, width } = this.props;
    const field = label;

    const marks = options.map(
      (o, i) => ({
        value: i,
        // to prevent overlapping of labels in the design
        label: ["lg", "xs"].includes(width) ? ((i == 0 || i == 3) && o.value.name || '') : o.value.name
      })
    );

    const defaultVal = parseInt(sessionStorage.getItem(field)) || 0;
    const maxVal = parseInt(_.maxBy(marks, o => (parseInt(o.value))).value);

    return (
      <div className={appendClassName("sui-facet", className)}>
        <div className="sui-facet__title" style={{color:'#333'}}>{label}</div>
          <Slider
            value={defaultVal}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="off"
            step={null}
            marks={marks}
            max={maxVal}
            onChange={this.onChange.bind(this)}
          />
      </div>
    );
  }
}

SingleSliderFacet.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(FacetValue).isRequired,
  className: PropTypes.string
};

export default withRouter(withWidth()(SingleSliderFacet));