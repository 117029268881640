import React from 'react';
import PropTypes from 'prop-types';

import {withTheme} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

import SearchField from 'components/Search/Field';
import {Helmet} from 'react-helmet';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import { Cookies } from "react-cookie-consent"
import { useMatomo } from '@datapunt/matomo-tracker-react';

import logo from './logo-suche.svg';

// import banner from 'mockups/search/img/banner.png';

const Start = (props) => {
    const consent = localStorage.getItem('consent');

    const {theme} = props;
    const vhCenter = theme.custom.vhCenter.style;

    const { pushInstruction } = useMatomo();

    const resetMatomoSettings = () => {
        localStorage.removeItem('consent');
        Cookies.remove('consent');
        Cookies.remove('consent-legacy');
        pushInstruction('forgetConsentGiven');

        window.location.reload();
    }

    return (
        <div>
            <Helmet>
                <style>{"body { background-color: #E8ECF4; }"}</style>
            </Helmet>
            <div>
                <div style={{
                    // backgroundImage: `url("${banner}")`,
                    // backgroundSize: 'cover',
                    // height: '50vh'
                }}>
                    <div style={vhCenter}>
                        <div style={{maxWidth: '50em'}}>
                            <div style={{paddingTop: '3em', paddingBottom: '3em'}}>
                                <div style={vhCenter}>
                                    <img src={logo} width="33%"/>
                                </div>
                                <br />
                                <Typography align="center" variant="subtitle1"
                                            style={{color: theme.palette.primary.main, fontWeight: 'bold'}}
                                            gutterBottom>
                                    Ihre Suche für evidenzbasierte Gesundheitsinformationen
                                </Typography>
                            </div>
                            <Alert severity="info">
                              <AlertTitle>Hinweis</AlertTitle>
                              Die hier veröffentlichte Version der Suchmaschine befindet sich noch in der Testphase 
                              und wird innerhalb des GAP-Forschungsprojektes bewertet und weiterentwickelt. 
                              Daher können auch Suchergebnisse erscheinen, die unpassend wirken.
                            </Alert>
                            <Alert severity="info">
                              { (consent !== 'in') ?
                              <span>
                                Im Rahmen unseres Forschungsprojektes würden wir gerne anonym Ihr Nutzerverhalten erfassen. 
                                Wir erstellen dabei keine persönlichen Nutzerprofile und leiten keine Daten an Dritte weiter.
                                <br/>
                                Momentan werden diese Daten bei Ihnen {consent !== 'in' && <b>nicht</b>} erfasst!</span> :
                              <span>
                                Sie haben momentan eingewilligt, dass wir im Rahmen unseres Forschungsprojektes Nutzerverhalten anonym erfassen. 
                                Wir erstellen dabei keine persönlichen Nutzerprofile und leiten keine Daten an Dritte weiter.
                              </span>
                              }
                              <p>
                              Ihre Entscheidung bezüglich der Datenerfassung können Sie ändern: klicken sie <a style={{ color: "red" }} href="#reset" onClick={() => resetMatomoSettings()}>hier</a> um das Cookie-Auswahlfenster am unteren Bildschirmrand erneut anzeigen zu lassen und ihre Auswahl neu zu treffen.
                              </p>
                            </Alert>
                            <div style={{paddingBottom: '10vw'}}>
                                <SearchField/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
Start.propTypes = {
    theme: PropTypes.object.isRequired
};


export default withTheme(Start);