import React from 'react';
import routes from 'routes';
import { BrowserRouter as Router, Route } from 'react-router-dom';
// import { createBrowserHistory } from "history";

import {isIE} from 'react-device-detect';

import TopBar from 'components/TopBar';
import Footer from 'components/Footer';

import { MuiThemeProvider } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import theme from 'themes/default/theme';

// search-ui
import "@elastic/react-search-ui-views/lib/styles/styles.css";

import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";

import CookieConsent from "react-cookie-consent";

import {
    SearchProvider,
    WithSearch,
    ErrorBoundary
} from "@elastic/react-search-ui";

import {
    buildAutocompleteQueryConfig,
    buildFacetConfigFromConfig,
    buildSearchOptionsFromConfig,
    getConfig
} from "config/config-helper";

import { useMatomo } from '@datapunt/matomo-tracker-react';
import config from 'config/config.json';
import statistics from 'config/thresholds.json';

import { getFilters } from 'helper/common';

const epsilon = 0.00001;

const categoryFacets = Object.keys(statistics).reduce(
    (obj, e) =>
        {
            if (obj) {
                obj[e] = {
                    type: "range",
                    ranges: [
                        { from: -1, name: "unwichtig" },
                        { from: statistics[e].q25+epsilon, name: "eher unwichtig" },
                        { from: statistics[e].q75+epsilon, name: "wichtig" },
                        { from: statistics[e].q75+epsilon, name: "sehr wichtig" }
                    ]
                }
            } else {
                obj = {}
            }
            return obj;
        }, 
    {}
);

const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();
const connector = new AppSearchAPIConnector({
    searchKey,
    engineName,
    hostIdentifier,
    endpointBase
});
const searchConfig = {
    debug: process.env.NODE_ENV === "development",
    searchQuery: {
        facets: {
            ...buildFacetConfigFromConfig(),
            ...categoryFacets
        },
        ...buildSearchOptionsFromConfig()
    },
    autocompleteQuery: buildAutocompleteQueryConfig(),
    apiConnector: connector
};

// const history = createBrowserHistory();
// console.log(process.env.NODE_ENV);

const App = () => {
    let key = 0;

    const { trackPageView, enableLinkTracking, pushInstruction } = useMatomo();

    pushInstruction('setCustomVariable', 1, 'filters', JSON.stringify(getFilters()), 'page');
    pushInstruction('setCustomVariable', 2, 'href', window.location.href, 'page');

    // Track page view
    trackPageView();
    enableLinkTracking();

    const enableTracker = () => {
      pushInstruction('setConsentGiven');
      pushInstruction('rememberConsentGiven');

      localStorage.setItem('consent', 'in');
      window.location.reload();
    }

    const disableTracker = () => {
      pushInstruction('forgetConsentGiven');

      localStorage.setItem('consent', 'out');
    }

    const accept = (x) => {
      enableTracker();
    }

    if (isIE)  
        return (
            <div style={{padding: '5em'}}>
                <Alert severity="error">
                    <AlertTitle>Veralterter Browser</AlertTitle>
                    Sie nutzen einen veralterten und inkompatiblen Browser<br/>
                    Dies stellt ein <a href="https://www.techbook.de/apps/software/microsoft-warnt-vor-ie">Sicherheitsrisiko</a> für Sie dar und wir empfehlen Ihnen daher auf einen der folgenden Browser zu wechseln:<br/>
                    <ul>
                        <li><a href="https://www.mozilla.org/de/firefox/new/">Firefox</a></li>
                        <li><a href="https://www.google.de/chrome/">Chrome</a></li>
                        <li><a href="https://www.opera.com/de">Opera</a></li>
                        <li><a href="https://www.microsoft.com/de-de/edge">Microsoft Edge</a></li>
                    </ul>
                </Alert>
            </div>
        );
    
    return (
        <MuiThemeProvider theme={theme}>
            <SearchProvider config={searchConfig}>
                <WithSearch mapContextToProps={({ wasSearched }) => ({ wasSearched })}>
                    {({ wasSearched }) => {
                        return (
                            <Router>
                                <div style={{
                                    display: 'flex',
                                    minHeight: '100vh',
                                    flexDirection: 'column'
                                }}>
                                    <header>
                                        <TopBar />
                                    </header>
                                    <main>
                                        <ErrorBoundary>
                                            {routes.map(route =>
                                                (<Route key={`route${key++}`} exact={route.exact} path={route.path}
                                                    component={route.component} />)
                                            )}
                                        </ErrorBoundary>
                                    </main>
                                    <Footer />
                                    {!localStorage.getItem('consent') ? <CookieConsent 
                                      acceptOnScroll={false}
                                      onAccept={(x) => accept(x)}
                                      location="bottom"
                                      buttonText="Akzeptieren"
                                      cookieName="consent"
                                      style={{ background: "#2B373B" }}
                                      buttonStyle={{ color: "#fff", backgroundColor: "#228B22" }}
                                      expires={150}
                                      enableDeclineButton
                                      onDecline={(x) => disableTracker()}
                                      declineButtonText="Ablehnen"
                                    >
                                      <div dangerouslySetInnerHTML={{__html: config.tracker.cookie.consentText}}/>
                                    </CookieConsent> : null}
                                </div>
                            </Router>
                        )
                    }}
                </WithSearch>
            </SearchProvider>
        </MuiThemeProvider>
    );
};

export default App;