import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from 'App';
import * as serviceWorker from 'serviceWorker';

import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'

import config from 'config/config.json';

const instance = createInstance({
  urlBase: config.tracker.url,
  trackerUrl: config.tracker.trackerUrl,
  srcUrl: config.tracker.srcUrl,
  siteId: config.tracker.site_id,
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  linkTracking: true, // optional, default value: true
  requireConsent: true,
  configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    setSecureCookie: config.tracker.url.startsWith('https'),
    setLinkClasses: 'external',
  }
});

ReactDOM.render(
<MatomoProvider value={instance}>
  <App />
</MatomoProvider>,
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
