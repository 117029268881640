import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import SearchField from './Field';

import { withTheme } from '@material-ui/core';
import { Helmet } from 'react-helmet';


import 'themes/default/reactive-search.css';

import Tooltip from '@material-ui/core/Tooltip';
import HtmlTooltip from 'components/HtmlTooltip';
import CustomMultiFacet from "components/Custom/MultiFacet";
import CustomResult from "components/Custom/Result";
import PagingInfoView from "components/Custom/PagingInfo";

import { Results } from "@elastic/react-search-ui";
import { Paging, PagingInfo } from "@elastic/react-search-ui";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import withWidth from '@material-ui/core/withWidth';

// import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import config from 'config/config.json';

import {
    getConfig,
    getFacetFields
} from "config/config-helper";

const Result = (props) => {
  const { theme } = props;
  const style = theme.custom.container.style;

  const strToBool = (value) => (String(value).toLowerCase() === 'true');

  const [collapsed, setCollapsed] = React.useState(
    sessionStorage.getItem('collapsed') !== undefined ? strToBool(sessionStorage.getItem('collapsed')) : true
  );

  React.useEffect(() => {
    sessionStorage.setItem('collapsed', collapsed);
  }, [collapsed]);

  const handleChange = (e, nE) => { 
    setCollapsed(!collapsed); 
  }

  return (
      <div style={style}>
          <Helmet>
              <title>{`Suchergebnisse für "${props.defaultValue}"`}</title>
          </Helmet>
          <div>
              <div style={{ paddingTop: '3em', paddingBottom: '3em' }}>
                  <Grid container 
                        spacing={10} 
                        direction={["lg","xl"].includes(props.width) ? "row-reverse" : "column"}
                        justify="center">
                    {/* <Grid item xs={12} lg={3}>
                      <div style={{width: '100%'}}>
                          {["lg","xl"].includes(props.width) ?
                          <div>
                            <Typography className="heading" style={{padding: '10%', marginLeft: '-20%', color: '#333'}}>
                              Qualitätsmerkmale&nbsp;
                              <HtmlTooltip title={config.tooltip.categories}>
                                <InfoOutlinedIcon fontSize="small" />
                              </HtmlTooltip>
                            </Typography>
                            <CustomMultiFacet header="Kategorien" fields={getFacetFields()} />
                          </div> :
                          <Accordion expanded={collapsed} onChange={handleChange} style={{border: "1px solid #ccc"}}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography className="heading" style={{color: '#333'}}>
                              Qualitätsmerkmale&nbsp;
                              <HtmlTooltip title={config.tooltip.categories}>
                                <InfoOutlinedIcon fontSizeSmall />
                              </HtmlTooltip>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails style={{paddingLeft: "3em", paddingRight: "3em"}}>
                            <CustomMultiFacet header="Kategorien" fields={getFacetFields()} />
                          </AccordionDetails>
                        </Accordion>}
                        </div>
                      </Grid> */}
                      <Grid item xs={12} lg={9}>
                          <h2>Suchergebnisse&nbsp;
                            <HtmlTooltip title={config.tooltip.results}>
                              <InfoOutlinedIcon fontSizeSmall />
                            </HtmlTooltip></h2>
                          <SearchField defaultValue={props.defaultValue} />

                          <PagingInfo view={PagingInfoView} />
                          <Results
                              resultView={CustomResult}
                              titleField={getConfig().titleField}
                              urlField={getConfig().urlField}
                              shouldTrackClickThrough={true}
                          />
                          <div style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                          }}>
                              <Paging />
                          </div>
                      </Grid>
                  </Grid>
              </div>
          </div>
      </div>
  );
};

Result.propTypes = {
    defaultValue: PropTypes.string,
    match: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

Result.defaultProps = {
    defaultValue: null
};

export default withWidth()(withTheme(withRouter(Result)));