import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import {withTheme} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import FullScreenDialog from './FullScreenDialog';
import { useLocation } from 'react-router-dom';

const TopBar = (props) => {
    const {theme} = props;
    const style = theme.custom.container.style;

    const location = useLocation();

    return (
        <div>
            <AppBar color="primary" position="static">
                <Toolbar style={style}>
                    <div>
                        <Typography variant="h6" color="inherit">
                            <Link style={{color: '#fff', textDecoration: 'none'}} to="/">
                                <b>tala-med Suche</b>
                            </Link>
                        </Typography>
                        <Typography variant="subtitle2" color="inherit" style={{marginTop:'-0.2em', paddingBottom: '0.2em'}}>
                            Evidenzbasierte Gesundheitsinformationen
                        </Typography>
                    </div>
                    {location.search && <FullScreenDialog />}
                </Toolbar>
            </AppBar>
        </div>
    )
};

TopBar.propTypes = {
    theme: PropTypes.object.isRequired
};

export default withTheme(TopBar);