import PropTypes from "prop-types";
import React from "react";
import Row from "components/Search/Result/Row";

import config from 'config/config.json';

const statistics = config.thresholds;

function isFieldValueWrapper(object) {
  return (
    object && (object.hasOwnProperty("raw") || object.hasOwnProperty("snippet"))
  );
}

function getFieldType(result, field, type) {
  if (result[field]) return result[field][type];
}

function getRaw(result, field) {
  return getFieldType(result, field, "raw");
}

function getSnippet(result, field) {
  return getFieldType(result, field, "snippet");
}

function htmlEscape(str) {
  if (!str) return "";

  return String(str)
    .replace(/&/g, "&amp;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#39;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
}

function getEscapedField(result, field) {
  // Fallback to raw values here, because non-string fields
  // will not have a snippet fallback. Raw values MUST be html escaped.
  const safeField =
    getSnippet(result, field) || htmlEscape(getRaw(result, field));
  return Array.isArray(safeField) ? safeField.join(", ") : safeField;
}

function getEscapedFields(result) {
  return Object.keys(result).reduce((acc, field) => {
    // If we receive an arbitrary value from the response, we may not properly
    // handle it, so we should filter out arbitrary values here.
    //
    // I.e.,
    // Arbitrary value: "_metaField: '1939191'"
    // vs.
    // FieldValueWrapper: "_metaField: {raw: '1939191'}"
    if (!isFieldValueWrapper(result[field])) return acc;
    
    let resultTmp = acc;
    resultTmp[field] = getEscapedField(result, field);

    return resultTmp;
  }, {});
}

function Result({
  className,
  result,
  onClickLink,
  titleField,
  urlField,
  ...rest
}) {
  const getLabel = (result, label) => ({
      label: label,
      value: getRaw(result, label)
    });

  const labels = Object.keys(statistics).map(
    (key) => (getLabel(result, key))
  );

  const data = {
    fields: getEscapedFields(result),
    title: getEscapedField(result, titleField),
    url: getRaw(result, urlField),
    content: { snippet: getSnippet(result, 'content'), raw: getRaw(result, 'content') },
    label: labels
  }

  return (
    <Row data={data} />
  );
}

Result.propTypes = {
  result: PropTypes.object.isRequired,
  onClickLink: PropTypes.func.isRequired,
  className: PropTypes.string,
  titleField: PropTypes.string,
  urlField: PropTypes.string
};

export default Result;
