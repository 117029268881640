import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { withTheme } from '@material-ui/core/styles';
import { SearchBox } from "@elastic/react-search-ui";
import CustomSearchUI from 'components/Custom/CustomSearchUI';

import {
    getFacetFields
} from "config/config-helper";


const Field = (props) => {
    const redirect = (value) => {
        // reset filters
        getFacetFields().map(
            e => {
                return sessionStorage.removeItem(`${e}_set`);
            }
        )
      
        window.location.href = `/?q=${encodeURIComponent(value)}`;
    };

    // const dataSearchTheme = props.theme.custom.reactiveSearch.dataSearch;

    // cssClass=searchbox
    return (
        <SearchBox
            autocompleteResults={false}
            view={CustomSearchUI}
            autocompleteSuggestions={true}
            onSubmit={searchTerm => {
                redirect(searchTerm);
            }}
            onSelectAutocomplete={(selection, { }, defaultOnSelectAutocomplete) => {
                if (selection.suggestion) {
                    redirect(selection.suggestion);
                } else {
                    defaultOnSelectAutocomplete(selection);
                }
            }}
            inputProps={{ placeholder: "Suchbegriff eingeben" }}
        />
    );
};

Field.propTypes = {
    history: PropTypes.object.isRequired,
    defaultValue: PropTypes.string,
    theme: PropTypes.object.isRequired
};

Field.defaultProps = {
    defaultValue: null
};

export default withRouter(withTheme(Field));