import React from 'react';

import { withTheme, withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  content: {
    ...theme.custom.contentpage.style
  },
  center: {
    ...theme.custom.verticalCenter.style
  },
  container: {
    ...theme.custom.container.style
  }
});

const Imprint = (props) => {
  return (
    <section className={props.classes.content}>
      <div className={props.classes.container}>
        <h2>Impressum</h2>
        <p><strong>Herausgeber</strong>
          <br /> Universitätsklinikum Freiburg
        <br /> Breisacher Straße 153
        <br /> D 79110 Freiburg
        <br /> Telefon: +49 761 270-0
        <br /> Telefax: +49 761 270-20200
        <br /><a href="mailto:info@uniklinik-freiburg.de" target="_blank" rel="noopener noreferrer">info@uniklinik-freiburg.de</a>
        </p>
        <p><strong>Gesamtverantwortung</strong>
          <br />Das Universitätsklinikum Freiburg ist eine rechtsfähige Anstalt des öffentlichen Rechts der Albert-Ludwigs-Universität Freiburg, die der Rechtsaufsicht durch das Ministerium für Wissenschaft, Forschung und Kunst Baden-Württemberg unterliegt. Es wird vertreten durch den Leitenden Ärztlichen Direktor, Prof. Dr. Frederik Wenz und die Kaufmännische Direktorin, Anja Simon. Umsatzsteuer-Identifikationsnummer: DE 811506626
      </p>
        <p><strong>Inhaltlich verantwortliche Person</strong>
          <br />Dr. Sebastian Voigt-Radloff
        <br />Institut für Evidenz in der Medizin (für Cochrane Deutschland Stiftung)
        <br />Breisacher Straße 86
        <br />D 79110 Freiburg
        <br />Leiter der Forschungsprojektes GAP – Gut informierte Kommunikation zwischen Arzt und Patient
        <br />
        <br />Förderkennzeichen: 01NVF17010
      </p>
        <p><strong>Inhalte dieser Website</strong>
          <br />Die Inhalte von suche.tala-med.info werden mit größtmöglicher Sorgfalt erstellt. Eine Gewähr für die Richtigkeit, Aktualität und Vollständigkeit der Inhalte kann dennoch nicht übernommen werden. Die angebotenen Gesundheitsinformationen schränken die Therapiefreiheit nicht ein. Für Therapieentscheidungen trägt der verordnende Arzt die medizinische und haftungsrechtliche Verantwortung. Nutzen und Risiken sind stets sorgfältig unter Einbeziehung von Therapiealternativen abzuwägen. Eine medizinisch-fachliche Begründung der Therapieentscheidung ist erforderlich.</p>
        <p><strong>Externe Links</strong>
          <br />suche.tala-med.info enthält Links zu externen Webseiten Dritter, für deren Inhalte wir keine Gewähr übernehmen können. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
        </p>
        <p><strong>Copyright-Hinweis zu Fotos und Grafiken</strong>
          <br />Alle verwendeten Bilder, soweit nicht anders ausgezeichnet: Copyright Institut für Evidenz in der Medizin (für Cochrane Deutschland Stiftung), Universitätsklinikum Freiburg
        </p>
        <p><strong>Anwendbares Recht</strong>
          <br />Es gilt ausschließlich das maßgebliche Recht der Bundesrepublik Deutschland.
        </p>
        <p><strong>Impressum des Universitätsklinikums Freiburg</strong>
          <br />Bitte beachten Sie auch das <a href="https://www.uniklinik-freiburg.de/footernavigation/impressum.html" target="_blank" rel="noopener noreferrer">Impressum des Universitätsklinikums Freiburg</a>, das, soweit anwendbar, auch für diese Seite gilt.
        </p>

        <p><strong>Gesamtrealisation</strong>
          <br />Institut für KI und Informatik in der Medizin
          <br />Klinikum rechts der Isar der Technischen Universität München
          <br />Ismaninger Straße 22
          <br />81675 München
          <br /><a href="https://www.mri.tum.de/ki-und-informatik" target="_blank" rel="noopener noreferrer">www.mri.tum.de/ki-und-informatik</a>
        </p>
        <p><strong>Hosting</strong>
          <br />Leibniz-Rechenzentrum (LRZ)
          <br />der Bayerischen Akademie der Wissenschaften
          <br />Boltzmannstraße 1
          <br />D-85748 Garching bei München
        </p>
        <p><strong>Gender Disclaimer</strong>
          <br />Aus Gründen der Lesbarkeit wurde im Text die männliche Form gewählt, nichtsdestoweniger beziehen sich die Angaben auf Angehörige beider Geschlechter.
        </p>
      </div>
    </section>
  )
}

export default withTheme(withStyles(styles)(Imprint));