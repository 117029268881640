import Search from 'pages/Search';
import Privacy from 'pages/Privacy';
import Imprint from 'pages/Imprint';
import About from 'pages/About';

const routes = [
    // search
    {path: '/', exact: true, name: 'Suche', component: Search},
    // {path: '/search', exact: true, name: 'Suchergebnis', component: Search},
    // {path: '/search/q/', exact: true, name: 'Suchergebnis', component: SearchRedirect},
    // {path: '/search/q/:query?', exact: true, name: 'Suchergebnis', component: Search},

    // bottom navigation
    {path: '/kontakt', exact: true, name: 'Kontakt', component: Search},
    {path: '/ueber_das_projekt', exact: true, name: 'Über das Projekt', component: Search},
    // {path: '/hilfe', exact: true, name: 'Hilfe', component: Search},

    {path: '/impressum', exact: true, name: 'Impressum', component: Imprint},
    {path: '/datenschutz', exact: true, name: 'Datenschutz', component: Privacy},
    {path: '/ueber', exact: true, name: 'Weitere Informationen', component: About},
];

export default routes;