import React from 'react';
import PropTypes from 'prop-types';
import Chip from './Chip';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { withSearch } from '@elastic/react-search-ui'
import className from 'classnames';

import statistics from 'config/thresholds.json';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import { getFilters } from 'helper/common';

const styles = theme => ({
    root: {
        width: '100%',
    },
    title: {
        color: theme.custom.colors.active,
        textDecoration: 'none',
        padding: 'none',
        margin: 'none',
        marginBlockEnd: 0
    },
    a: {
        textDecoration: 'none'
    },
    url: {
        paddingBottom: '1.5vh',
        color: theme.palette.primary.main,
        display: "block"
    },
    content: {},
    chips: {

        padding: '1.5vh 0 3.5vh 0',
    },
    chip: {
        borderRadius: 0,
        fontWeight: 'bold',
        marginRight: '0.5vw'
    }
});

const truncate = (str, limit) => {
    const result = str.trim().split(' ').slice(0, limit).join(' ');

    if (result.length > limit)
        return result + ' ...';
    else
        return result;
};

// const stripHtml = (html) => {
//     let tmp = document.createElement("DIV");
//     tmp.innerHTML = html;

//     return tmp.textContent || tmp.innerText || "";
// }

const firstSentenceWithWord = (word, content) => {
    return content;
}

const replaceHTMLTag = (string, find, replace) => {
    if (!string) return string;

    let result = string.replace(`<${find}>`, `<${replace}>`);
    result = result.replace(`</${find}>`, `</${replace}>`);

    return result;
}

const getIcon = (item, icons) => {
    const thresholds = statistics[item.label]

    // select correct icon
    let result = icons.q25;

    if (item.value > thresholds.q75)
        result = icons.qRest;
    else if (item.value > thresholds.q50)
        result = icons.q75;
    else if (item.value > thresholds.q25)
        result = icons.q50;

    return result;
}

const Row = (props) => {
    const res = props.data;
    const icons = props.theme.custom.search.results.icons;

    const { pushInstruction } = useMatomo();
    // const getFilters = () => (
    //   {
    //     aktuell: sessionStorage.getItem('aktuell') || 0,
    //     nutzerfreundlich: sessionStorage.getItem('nutzerfreundlich') || 0,
    //     vertrauenswuerdig: sessionStorage.getItem('vertrauenswuerdig') || 0,
    //     verstaendlich: sessionStorage.getItem('verstaendlich') || 0,
    //   }
    // )

    const truncate = (text, max_chars) => {    
        return (text.length > max_chars) ? text.substring(0, max_chars) + '...' : text ;
    }

    const formatURL = url => {
        let result = url.replace('https://', '');
        result = result.replace('http://', '');

        result = truncate(result, 64);

        if (result.charAt(result.length-1) == '/') result = result.substring(0,result.length-2);

        result = result.replace(/\//g, ' › ');

        return result;
    }

    return (
        <div className={props.classes.root}>
            <a className={className(props.classes.a, "external")} 
            target="_blank"  rel="noopener noreferrer" href={res.url}
            // due to matomo bug, we need to do this manually
            onClick={(e) => { 
              pushInstruction('trackLink', res.url, 'link');
              pushInstruction('trackEvent', 'filtersOnClick', JSON.stringify(getFilters()));
              
              // pushInstruction('setCustomVariable', 1, 'filters', JSON.stringify(getFilters()), 'page');
              // trackPageView();
            }}
            >
                <Typography className={props.classes.title} gutterBottom variant="h5" component="h2">
                    <span dangerouslySetInnerHTML={{
                        __html: replaceHTMLTag(res.title, 'em', 'b')
                    }}></span>
                </Typography>

                <div className={props.classes.url}>
                    {formatURL(res.url)}
                </div>
            </a>

            <div
                dangerouslySetInnerHTML={{
                    __html: replaceHTMLTag(res.content.snippet, 'em', 'b') ||
                        replaceHTMLTag(truncate(firstSentenceWithWord(props.searchTerm, res.content.raw), 25), 'em', 'b')
                }}
                className={props.classes.content}
                style={{borderBottom: '1px #ccc solid', paddingBottom: '2em', marginBottom: '2em'}}
            />

            {/* <div className={props.classes.chips}>
                {res.label && res.label.map((item, index) => (
                    <Chip key={index} caption={item.label} icon={getIcon(item, icons)} />
                ))}
            </div> */}
        </div>
    );
};

Row.propTypes = {
    data: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
};

const mapContextToProps = ({ searchTerm }) => ({
    searchTerm
});

export default withSearch(mapContextToProps)(withTheme(withStyles(styles)(Row)));