import React from 'react';
import { createMuiTheme } from '@material-ui/core';
import uklLogo from './img/logo_ukl_cmyk.svg';
import tudLogo from './img/tud_logo_rgb.jpg';
import cochraneLogo from './img/Cochrane_DEU_rgb.svg';
import iconTextsize from './img/textsize.svg';
import iconContrast from './img/contrast.svg';
import mriLogo from './img/Klinikum_rechts_der_Isar_logo.svg';
import tumLogo from './img/TUM_Logo_extern_mt_DE_RGB_neg_s.svg';

import imgQ25 from './img/1bl3gr.svg';
import imgQ50 from './img/2bl2gr.svg';
import imgQ75 from './img/3bl1gr.svg';
import imgQRest from './img/4bl0gr.svg';

// import iconSearch from './img/'

const icon = () => {
    return <img alt="Suche" src={iconTextsize} />
};

const theme = createMuiTheme({
    // setup new typography standard
    typography: {
    },

    // TODO: reactive-search theme coloring
    colors: {
        textColor: '#424242',
        primaryTextColor: '#fff',
        primaryColor: '#004a99',
        titleColor: '#424242',
        alertColor: '#d9534f',
        borderColor: '#E8ECF4',
    },

    // customize component setup
    props: {
        MuiButton: {
            style: {
                backgroundColor: '#004a99',
                color: '#fff',
                borderRadius: 0,
            }
        }
    },

    //
    palette: {
        primary: {
            main: '#004a99'
        },
        secondary: {
            main: '#ffffff'
        },
    },

    // disable all shadows
    shadows: Array(25).fill("none"),

    custom: {
        search: {
            results: {
                icons: {
                    q25: imgQ25,
                    q50: imgQ50,
                    q75: imgQ75,
                    qRest: imgQRest
                }
            }
        },
        colors: {
            active: '#048061',
            background: '#f1f4f8'
        },
        contentpage: {
            style: {
                color: "#424242"
            }
        },
        footer: {
            accessibility: {
                icons: {
                    textsize: iconTextsize,
                    contrast: iconContrast
                }
            },
            logos: {
                tum: {
                    file: tumLogo,
                    style: {
                        padding: '0.5em',
                        maxWidth: '150px'
                    }
                },
                mri: {
                    file: mriLogo,
                    style: {
                        padding: '0.5em',
                        maxWidth: '150px'
                    }
                },
                ukl: {
                    file: uklLogo,
                    style: {
                        padding: '0.5em',
                        maxWidth: '150px'
                    }
                },
                cochrane: {
                    file: cochraneLogo,
                    style: {
                        padding: '0.5em',
                        maxWidth: '150px'
                    }
                },
                tud: {
                    file: tudLogo,
                    style: {
                        padding: '0.5em',
                        maxWidth: '150px'
                    }
                }
            },
            style: {
                borderTop: '#f1f4f8 solid 3px',
                backgroundColor: '#fff',
                paddingBottom: '2vh',
                paddingTop: '2vh'
            }
        },
        container: {
            style: {
                margin: '0 auto',
                width: '80%',
            }
        },
        verticalCenter: {
            style: {
                height: '100%',
                display: 'flex',
                alignItems: 'center'
            }
        },
        vhCenter: {
            style: {
                height: '100%',
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center'
            }
        },
        reactiveSearch: {
            dataSearch: {
                style: {
                    // margin: '0 auto',
                    // width: '50vw'
                },
                iconPosition: 'right',

                // FIXME: not working yet
                icon: icon
            }
        }
    }
});

export default theme;