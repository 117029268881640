import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';

import HtmlTooltip from 'components/HtmlTooltip';


import config from 'config/config.json';

const tooltip = config.tooltip;

const styles = (theme) => ({
  chipContainer: {
    backgroundColor: '#e7edf4',
    border: 'none',
    padding: '3px 20px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    margin: '4px 2px',
    cursor: 'pointer',
    borderRadius: '2px'
  },
  chipText: {
    textAlign: 'center',
    textDecoration: 'none',
    color: '#232323',
    fontWeight: "bold",
    display: 'block',
    paddingBottom: "2px"
  },
  chipImageContainer: {
    verticalAlign: 'middle',
    textAlign: 'center',
    display: 'block',
    paddingBottom: "2px"
  },
  chipImage: {
    display: 'block',
    float: 'none',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: '0 auto'
  }
});

const Chip = (props) => {
  return (
    <HtmlTooltip title={tooltip[props.caption]}>
      <div className={props.classes.chipContainer}>
        <span className={props.classes.chipText}>{props.caption}</span>
        <div className={props.classes.chipImageContainer}>
          <img alt={props.caption} className={props.classes.chipImage} src={props.icon} />
        </div>
      </div>
    </HtmlTooltip>

  );
};

Chip.propTypes = {
  icon: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired
};

export default withTheme(withStyles(styles)(Chip));