import React from 'react';

import PropTypes from 'prop-types';

import List from '@material-ui/core/List';

import _nav from 'config/config.json';
import {withStyles} from '@material-ui/core/styles';
import ListItemLink from './FooterNavigation/ListItemLink';


const styles = theme => ({
    grid: {
        ...theme.custom.verticalCenter.style
    }
});

const FooterNavigation = () => {
    return (
        <div style={{float: 'right'}}>
            <List component="nav" dense={true}>
                {_nav.navigation.bottom.map(
                    (item, index) => (
                        <ListItemLink key={index} to={item.url}
                                        primary={item.name} exact={item.exact}/>)
                )}
            </List>
        </div>
    )
};

FooterNavigation.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FooterNavigation);